import { Box, Grid, Typography, Button, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '@fontsource/inter';

function WeAreDetailedMessage() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery("(max-width: 600px)");
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md')); // Check for medium-sized screens

    return (
        <Box
            sx={{
                paddingLeft: isSmallScreen ? "10px" : isMediumScreen ? '10px' : '120px',
                paddingTop: isMediumScreen ? '20px' : '100px',
                backgroundColor: '#FFF',
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    <Typography
                        variant="h4"
                        component="h2"
                        gutterBottom
                        sx={{
                            color: "rgba(255, 145, 48, 1)",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: { xs: "16px", sm: "18px", md: "16px", lg: "22px" },
                            lineHeight: "26px",
                            letterSpacing: "4px",
                            fontWeight: "600",
                        }}
                    >
                        WE ARE
                    </Typography>

                    <Typography
                        variant="h5"
                        component="h1"
                        gutterBottom
                        sx={{
                            color: "var(--Primary-Dark, #0B132B)",
                            fontFamily: "Inter",
                            fontSize: { xs: "36px", sm: "36px", md: "24px", lg: "48px", xl: "60px" },
                            fontWeight: "800",
                            lineHeight: "140%",
                        }}
                    >
                        Your trusted partner for guaranteed software delivery
                    </Typography>
                </Grid>

                <Grid item xs={12} xl={10}>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            color: 'var(--gray-600, #52525B)',
                            fontFamily: 'Inter',
                            fontSize: { xs: "14px", sm: "18px", md: "14px", lg: "18px" },
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '160%',
                        }}
                    >
                        Our multi-skilled, cross-functional team of experts can provide services bespoke to your specific requirements ,
                        helping you streamline and enhance your business operations for the biggest returns.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: '16px' }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'var(--Complementary-Dark, #FF9130)',
                            width: "191px",
                            padding: { xs: '8px 20px 8px 44px', sm: '8px 8px 20px 8px 44px', xl: '8px 20px 8px 44px', lg: '8px 20px 8px 44px' },
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '0px',
                            '&:hover': {
                                backgroundColor: 'var(--Complementary-Dark, #FF9130)',
                                '& .MuiTypography-root': {
                                    paddingRight: '10px',
                                },
                                '& .MuiSvgIcon-root': {
                                    visibility: 'visible',
                                },
                            },
                        }}
                        endIcon={<ArrowForwardIcon sx={{ color: 'var(--Primary-Dark, #0B132B)', visibility: 'hidden' }} />} // Hide the icon initially
                        onClick={() => window.open("https://cal.com/naresh-shanmugaraj/discussion", "_blank")}
                    >
                        <Typography
                            sx={{
                                color: 'var(--Primary-Dark, #0B132B)',
                                fontFamily: 'Inter',
                                fontSize: { xs: "16px", sm: "18px", md: "20px" },
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '40px',
                                transition: 'padding-right 0.3s',
                            }}
                        >
                            Let’s Talk
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default WeAreDetailedMessage;
