import React from "react";
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography, makeStyles, useMediaQuery } from "@mui/material";
import uiux from "../Assets/uiux.png"
import web from "../Assets/webdevelopment.png"
import mobile from "../Assets/mobileAppDevelopment.png"
import saas from "../Assets/saas.png"
import Ai from "../Assets/AI.png"
import bluebackground from "../Assets/ServicesBG.jpg"
import { useTheme } from '@mui/material/styles';



function OurServices() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery("(max-width: 600px)");
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));



    return (


        <Grid
            container
            spacing={2}
            alignItems='center'
            style={{
                backgroundImage: `url(${bluebackground})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '100vh', // Adjust as needed
                padding: isSmallScreen ? "80px 24px" : isMediumScreen ? " 80px 20px" : "80px 120px",
                justifyContent: "center",
                [theme.breakpoints.down("sm")]: {
                    padding: "20px",
                },
                [theme.breakpoints.down("md")]: {
                    padding: "20px",
                },
                [theme.breakpoints.down("lg")]: {
                    padding: "20px",
                },
                ".card": {
                    border: "none",
                    boxShadow: "none",
                },
            }}
        >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                sx={{
                    padding: "40px",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <Typography
                    component="h2"
                    style={{
                        color: 'var(--Complementary-Dark, #FF9130)',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: isSmallScreen ? '16px' : '18px',
                        fontWeight: 600,
                        lineHeight: '26px',
                        letterSpacing: '4px',
                    }}
                >
                    OUR SERVICES
                </Typography>
                <Typography
                    style={{
                        color: 'var(--white, #FFF)',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: isSmallScreen ? '36px' : '42px',
                        fontWeight: 1000,
                        lineHeight: '48px',

                    }}
                >
                    We make your vision into Reality
                </Typography>
                <Typography
                    variant="body1"
                    style={{
                        color: 'var(--gray-200, #E5E7EB)',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        paddingTop: "12px"
                    }}
                >
                    Transform your business with one of the best product development firm                </Typography>
            </Grid>

            <Grid container spacing={1} paddingBottom={0} sx={{ display: "flex", justifyContent: "center" }} >
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ maxWidth: 322, padding: "24px", border: "none", boxShadow: "none", display: "flex", flexDirection: "column", alignItems: "flex-start", background: "transparent" }}>
                        <img
                            style={{ height: "52px", width: "52px" }}
                            src={uiux}
                        />
                        <CardContent sx={{ padding: "0px" }}>
                            <Typography style={{ padding: "40px" }}></Typography>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="h3"
                                style={{
                                    color: 'var(--Complementary-Dark, #FF9130)',
                                    fontFamily: 'Inter',
                                    fontSize: '22px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: 'normal',
                                    paddingBottom: "8.3px"

                                }}
                            >
                                UI/UX Design
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                style={{
                                    color: 'var(--white, #FFF)',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '160%',
                                }}
                            >
                                Our user experience design service employs a meticulous process to ensure we fulfill your requirements and deliver exceptional outcomes.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ maxWidth: 322, padding: "24px", border: "none", boxShadow: "none", display: "flex", flexDirection: "column", alignItems: "flex-start", background: "transparent" }}>
                        <img
                            style={{ height: "52px", width: "52px" }}
                            src={web}
                        />
                        <CardContent sx={{ padding: "0px" }}>
                            <Typography style={{ padding: "40px" }}></Typography>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="h3"
                                style={{
                                    color: 'var(--Complementary-Dark, #FF9130)',
                                    fontFamily: 'Inter',
                                    padding: "0px",
                                    fontSize: '22px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: 'normal',
                                    paddingBottom: "8.3px"
                                }}
                            >
                                Web Development
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                style={{
                                    color: 'var(--white, #FFF)',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '160%',

                                }}
                            >
                                We offer a broad spectrum of web development services to fully tap into the capabilities offered by modern web technologies </Typography>
                        </CardContent >
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ maxWidth: 322, padding: "24px", border: "none", boxShadow: "none", display: "flex", flexDirection: "column", alignItems: "flex-start", background: "transparent" }}>
                        <img
                            style={{ height: "52px", width: "52px" }}
                            src={mobile}
                        />
                        <CardContent sx={{ padding: "0px" }}>
                            <Typography style={{ padding: "40px" }}></Typography>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="h3"
                                style={{
                                    color: 'var(--Complementary-Dark, #FF9130)',
                                    fontFamily: 'Inter',
                                    fontSize: '22px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: 'normal',
                                    paddingBottom: "8.3px"
                                }}
                            >
                                Mobile App Development
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                style={{
                                    color: 'var(--white, #FFF)',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '160%',
                                    width: "322px"

                                }}
                            >
                                Our entire mobile app development process is aligned to meet the timelines and focus on accelerating change by employing a combination of development approaches.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ maxWidth: 322, padding: "24px", border: "none", boxShadow: "none", display: "flex", flexDirection: "column", alignItems: "flex-start", background: "transparent" }}>
                    <img
                        style={{ height: "52px", width: "52px" }}
                        src={saas}
                    />
                    <CardContent sx={{ padding: "0px" }}>
                        <Typography style={{ padding: "40px" }}></Typography>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="h3"
                            style={{
                                color: 'var(--Complementary-Dark, #FF9130)',
                                fontFamily: 'Inter',
                                fontSize: '22px',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                lineHeight: 'normal',
                                paddingBottom: "8.3px"
                            }}
                        >
                            SaaS
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            style={{
                                color: 'var(--white, #FFF)',
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '160%',

                            }}
                        >
                            Boost your business innovation with Cogntix - SaaS product development  service, that helps you develop high-quality SaaS solutions.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ maxWidth: 322, padding: "24px", border: "none", boxShadow: "none", display: "flex", flexDirection: "column", alignItems: "flex-start", background: "transparent" }}>
                    <img
                        style={{ height: "52px", width: "52px" }}
                        src={Ai}
                    />
                    <CardContent sx={{ padding: "0px" }}>
                        <Typography style={{ padding: "40px" }}></Typography>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="h3"
                            style={{
                                color: 'var(--Complementary-Dark, #FF9130)',
                                fontFamily: 'Inter',
                                fontSize: '22px',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                lineHeight: 'normal',
                                paddingBottom: "8.3px"

                            }}
                        >
                            AI
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            style={{
                                color: 'var(--white, #FFF)',
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '160%',

                            }}
                        >
                            As an AI software company, we help companies implement AI into business processes strategically and effectively.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default OurServices;