import React from "react";
import TopNavBar from "./Sections/TopNavBar";
import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import ImageHeroFirstrow from "./Sections/ImageHeroFirstrow";
import WeAreDetailedMessage from "./Sections/WeAreDetailedMessage";
import WhoWeAreDetailedMessage from "./Sections/WhoWeAreDetailed";
import OurServices from "./Sections/OurServices";
import ReadyToBuild from "./Sections/ReadyToBuild";
import OurTeamAccomplishment from "./Sections/OurTeamAccomplishment";
import GetInTouchWithUs from "./Sections/GetInTouchWithUs";
import Footer from "./Sections/Footer";
import Mobilefooter from "./Sections/Mobilefooter";
import GroupImage3 from "./Sections/GroupImage3";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet";

function App() {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isxlargeScreen = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content="Empowering Digital Transformation Together"
        />
        <meta
          name="description"
          content="We deliver innovative solutions tailored to your business needs, helping you navigate the digital landscape with ease. Partner with us for growth and seamless user experiences."
        />
        <meta
          name="keywords"
          content="Software Development Services, Custom Software Solutions, SaaS Development Solutions, UI/UX Design, Software companies in Jaffna, Jaffna Software company"
        />
      </Helmet>
      <Box sx={{ width: "100%", minHeight: "100vh", backgroundColor: "#FFF" }}>
        <Grid
          container
          spacing={1}
          sx={{ paddingBottom: "100px" }}
          id="aboutUs"
        >
          <Grid item xs={12} md={6} lg={6} xl={6} sx={{ paddingLeft: "0px" }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={isSmallScreen ? "center" : "flex-start"}
              justifyContent={isSmallScreen ? "center" : "flex-start"}
              height="100%"
            >
              <TopNavBar />
              <WeAreDetailedMessage />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} xl={4.5} lg={6}>
            <Box
              display="flex"
              justifyContent={isSmallScreen ? "center" : "flex-start"}
              alignItems={isSmallScreen ? "center" : "flex-start"}
              height="100%"
              width="100%"
            >
              <ImageHeroFirstrow />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ padding: "0px" }} id="whoWeAre">
          <Grid item xs={12} md={6} lg={6} xl={5.75}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={isSmallScreen ? "center" : "flex-start"}
              justifyContent={isSmallScreen ? "center" : "flex-start"}
              height="100%"
            >
              <WhoWeAreDetailedMessage />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} xl={4.5} lg={6}>
            <GroupImage3 />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{ paddingTop: "20px", marginTop: "100px" }}
          id="ourServices"
        >
          <Grid item xs={12} md={12} xl={12} lg={12}>
            <OurServices />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ paddingTop: "20px", marginTop: "40px" }}
          id="readyToBuild"
        >
          <Grid item xs={12} md={12} xl={12} lg={12}>
            <ReadyToBuild />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{ paddingTop: "20px", marginTop: "40px" }}
          id="OurStory"
        >
          <Grid item xs={12} md={12} xl={12} lg={12}>
            <OurTeamAccomplishment />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{ paddingTop: "20px", marginTop: "40px" }}
          id="getInTouch"
        >
          <Grid item xs={12} md={12} xl={12} lg={12}>
            <GetInTouchWithUs />
          </Grid>
        </Grid>
        <Container maxWidth={isSmallScreen ? "xs" : "xl"}></Container>
        <Grid
          container
          spacing={2}
          sx={{ paddingTop: "0px", marginTop: "40px" }}
        >
          <Grid item xs={12} md={12} xl={12} lg={12}>
            {isSmallScreen ? <Mobilefooter /> : <Footer />}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default App;
