import React from 'react';
import { Box, Button, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import orangeLogo from "../Assets/Logo_Orange.png";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BLACKBG from "../Assets/BGBLACK.svg"
import { BackHand } from '@mui/icons-material';
import styled from 'styled-components';
function Footer() {
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

    const StyledInstagramIcon = styled(InstagramIcon)`
        background: #1E293B;
        color: #FFF;
        font-size: 24px;
        padding: 5px;
        border-radius: 50%;
        transition: background-color 0.3s ease;

        &:hover {
            background: #FF9130;
            color: #1E293B;
        }
    `;
    const StyledfacebookIcon = styled(FacebookOutlinedIcon)`
        background: #1E293B;
        color: #FFF;
        font-size: 24px;
        padding: 5px;
        border-radius: 50%;
        transition: background-color 0.3s ease;

        &:hover {
            background: #FF9130;
            color: #1E293B;
        }
    `;
    const StyledLinkedinIcon = styled(LinkedInIcon)`
        background: #1E293B;
        color: #FFF;
        font-size: 24px;
        padding: 5px;
        border-radius: 50%;
        transition: background-color 0.3s ease;

        &:hover {
            background: #FF9130;
            color: #1E293B;
        }
    `;


    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Grid
            container
            spacing={0}
            sx={{
                width: "100%",
                height: "363px",
                padding: "100px 120px",
                justifyContent: "space-between",
                alignItems: "center",
                background: "linear-gradient(0deg, #0B132B 0%, #0B132B 100%), url(<path-to-image>) lightgray 50% / cover no-repeat",
                ...(isExtraLargeScreen && {
                    padding: "60px 200px", // Adjust padding for full width
                })
            }}
        >
            <Grid container display={"flex"} direction={isSmallScreen ? "column" : (isMediumScreen ? "column" : "row")}>
                <Grid item xs={12} lg={12} xl={12}>
                    <Grid
                        container
                        display={"flex"}
                        direction={isMediumScreen ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            paddingBottom: "0px",
                            width: "100%",
                        }}
                    >
                        <Grid item sx={{ display: "flex", justifyContent: "flex-start", }}>
                            <img src={orangeLogo} alt="Orange Logo" style={{ width: isSmallScreen ? "144px" : "161px", height: isSmallScreen ? "61px" : "61px", objectFit: "cover", paddingBottom: isMediumScreen ? "20px" : "0px" }} />
                        </Grid>
                        <Grid item sm={6} md={6} sx={{ display: "flex", flexDirection: "row", padding: " 0px 60px", justifyContent: "space-around", gap: isMediumScreen ? "60px" : "0px", paddingBottom: isMediumScreen ? "40px" : "0px" }}>
                            <div onClick={() => scrollToSection('aboutUs')}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#F4F4F5',
                                        fontFamily: 'Inter',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '20px',
                                        marginBottom: 0,
                                        whiteSpace: 'nowrap',
                                        '&:hover': {
                                            color: 'orange',
                                            cursor: 'pointer'
                                        },
                                    }}
                                >
                                    About Us
                                </Typography>
                            </div>
                            <div onClick={() => scrollToSection('ourServices')}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#F4F4F5',
                                        fontFamily: 'Inter',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                        marginBottom: "0px",
                                        marginLeft: "20px",
                                        '&:hover': {
                                            color: 'orange',
                                            cursor: 'pointer'
                                        },
                                    }}
                                >
                                    Services
                                </Typography>
                            </div>
                            <div onClick={() => scrollToSection('OurStory')} >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#F4F4F5',
                                        fontFamily: 'Inter',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                        marginBottom: "0px",
                                        marginLeft: "20px",
                                        whiteSpace: 'nowrap',
                                        '&:hover': {
                                            color: 'orange',
                                            cursor: 'pointer'
                                        },
                                    }}
                                >
                                    Our Story
                                </Typography>
                            </div>
                            <div onClick={() => scrollToSection('getInTouch')}



                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#F4F4F5',
                                        fontFamily: 'Inter',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '24px',

                                        marginBottom: "0px",
                                        marginLeft: "20px",
                                        '&:hover': {
                                            color: 'orange',
                                            cursor: 'pointer'
                                        },

                                    }}
                                >
                                    Contact
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item sx={{ display: "flex", justifyContent: "flex-end", gap: isMediumScreen ? "30px" : "12px", padding: "20px 0px" }}>
                            <a href="https://www.linkedin.com/company/cogntix" target="_blank" rel="noopener noreferrer">
                                <  StyledLinkedinIcon />
                            </a>
                            <a href="https://www.facebook.com/WeCogntix/" target="_blank" rel="noopener noreferrer">
                                <StyledfacebookIcon />
                            </a>
                            <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                <StyledInstagramIcon />

                            </a>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>
            <Grid container display={"flex"} direction="row">

                <Grid item xs={12} lg={12} sx={{
                    display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between", paddingTop: "40px", borderTop: isMediumScreen ? "1px solid #1E293B" : "1px solid #1E293B",
                }}>
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#CBD5E1',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '22px',
                        }}
                    >
                        32 Main Street, Jaffna, Sri Lanka, 40000
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#CBD5E1',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '22px',
                        }}
                    >
                        © Copyright 2024, All Rights Reserved by Cogntix
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Footer;
