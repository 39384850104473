import React from "react";
import { Box, Button, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import success from "../Assets/successicon.png";
import feasto3mobile from "../Assets/Feastomobile3.png";
import feasto2mobile from "../Assets/Feastomobile2.png";
import { useTheme } from '@mui/material/styles';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

function OurTeamAccomplishment() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLargeScreen = useMediaQuery('(min-width: 900px) and (max-width: 1200px)');

    return (
        <Grid
            container
            sx={{
                paddingLeft: "120px",
                paddingRight: "120px",
                justifyContent: "center",
                [theme.breakpoints.down("sm")]: {
                    paddingLeft: "10px",
                    paddingRight: "10px",
                },
                [theme.breakpoints.down("md")]: {
                    paddingLeft: "40px",
                    paddingRight: "40px",
                },
                [theme.breakpoints.between("md", "lg")]: {
                    paddingLeft: "60px",
                    paddingRight: "60px",
                },

                ".card": {
                    border: "none",
                    boxShadow: "none",
                },
            }}
        >
            <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={6}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                    paddingBottom: "59px",
                    paddingRight: "20px",
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: "90%",
                        height: "100%",
                        flexShrink: 0,
                        borderRadius: "60px",
                        padding: isSmallScreen ? "19.4px 31.29px 18.78px 82.62px" : "19.4px 7.29px 18.78px 6.62px",
                        backgroundImage: 'linear-gradient(to bottom right, rgba(220, 41, 44, 0.08) 50%, rgba(220, 41, 44, 0.18) 55%)',
                    }}
                >
                    <img
                        src={(isSmallScreen || isMediumScreen || isLargeScreen) ? feasto2mobile : feasto3mobile}
                        alt="Feasto Mobile"
                        height={(isSmallScreen || isMediumScreen || isLargeScreen) ? "384px" : "556px"}
                        width={(isSmallScreen || isMediumScreen || isLargeScreen) ? "284px" : "556px"}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}
                    />
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={6}

                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "0px 0px 0px 0px",
                    [theme.breakpoints.down("md")]: {
                        padding: "0px 0px 0px 0px",
                    },



                }}
            >
                <Typography
                    variant="h4"
                    component="h2"
                    sx={{
                        color: "rgba(255, 145, 48, 1)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontWeight: "600",
                        textAlign: isSmallScreen ? "left" : "left",
                    }}
                >
                    OUR STORY
                </Typography>
                <Typography
                    variant="h5"
                    component="h2"
                    sx={{
                        color: "var(--Primary-Dark, #0B132B)",
                        fontFamily: "Inter",
                        fontSize: "36px",
                        fontWeight: "800",
                        lineHeight: "140%",
                        textAlign: isSmallScreen ? "left" : "left",
                    }}
                >
                    {isSmallScreen ? (
                        <>
                            FEASTO, Our <br />
                            team’s proud <br />
                            Accomplishment
                        </>
                    ) : (
                        <>
                            FEASTO, Our team’s proud <br />
                            Accomplishment.
                        </>
                    )}
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    sx={{
                        color: 'var(--gray-600, #52525B)',
                        fontFamily: 'Inter',
                        fontSize: "18px",
                        fontWeight: '400',
                        lineHeight: '28.8px',
                        textAlign: isSmallScreen ? "left" : "left",
                        paddingBottom: "40px",
                        gap: "40px"
                    }}
                >
                    Our team has successfully developed and implemented FEASTO, a platform tailored for small and micro businesses. FEASTO enables these enterprises to establish an online presence effortlessly, requiring no technical expertise, with just a three-minute setup.
                </Typography>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: 'var(--Complementary-Dark, #FF9130)',
                        width: "220px",
                        padding: '8px 24px',
                        position: "relative",
                        "& .MuiButton-endIcon": {
                            opacity: 0,
                            transition: "opacity 0.3s ease-in-out",
                            position: "absolute",
                            top: "50%",
                            right: "30px",
                            transform: "translateY(-50%)",
                        },
                        "&:hover": {
                            padding: "8px 48px 8px 20px",
                            backgroundColor: "var(--Complementary-Dark, #FF9130)",
                            "& .MuiButton-endIcon": {
                                opacity: 1,
                                marginRight: "4px",
                            },
                        },
                    }} endIcon={<LaunchRoundedIcon sx={{ color: "var(--Primary-Dark, #0B132B)" }} />}
                    onClick={() => window.open("https://feasto.io/", "_blank")}
                >
                    <Typography
                        sx={{
                            color: 'var(--Primary-Dark, #0B132B)',
                            fontFamily: 'Inter',
                            fontSize: { xs: "16px", lg: "18px", md: "18px" },
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '40px',
                        }}
                    >
                        View our work
                    </Typography>
                </Button>
                <Divider sx={{ width: '100%', padding: "16px" }} />
                <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", paddingTop: "10px" }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "25px" }}>
                                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                                    <img src={success} alt="Success Icon" width="24px" height="24px" />
                                    <Typography
                                        sx={{
                                            color: "var(--gray-900, #111827)",
                                            fontFamily: "Inter",
                                            fontSize: "18px",
                                            fontWeight: 600,
                                            lineHeight: "160%",
                                        }}
                                    >  Mobile-responsive layout.
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                                    <img src={success} alt="Your Image" width="24px" height="24px" />
                                    <Typography
                                        sx={{
                                            color: "var(--gray-900, #111827)",
                                            fontFamily: "Inter",
                                            fontSize: "18px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "160%",
                                        }}
                                    >
                                        Secure payment processing system.
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                                    <img src={success} alt="Your Image" width="24px" height="24px" />
                                    <Typography
                                        sx={{
                                            color: "var(--gray-900, #111827)",
                                            fontFamily: "Inter",
                                            fontSize: "18px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "160%",
                                            paddingBottom: isSmallScreen ? "18px" : isMediumScreen ? "25px" : "0px",
                                        }}
                                    > User-friendly interface design
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "25px" }}>
                                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                                    <img src={success} alt="Success Icon" width="24px" height="24px" />
                                    <Typography
                                        sx={{
                                            color: "var(--gray-900, #111827)",
                                            fontFamily: "Inter",
                                            fontSize: "18px",
                                            fontWeight: 600,
                                            lineHeight: "160%",
                                        }}
                                    >
                                        Order tracking functionality.
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                                    <img src={success} alt="Your Image" width="24px" height="24px" />
                                    <Typography
                                        sx={{
                                            color: "var(--gray-900, #111827)",
                                            fontFamily: "Inter",
                                            fontSize: "18px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "160%",
                                        }}
                                    >
                                        Integrated customer review system.
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                                    <img src={success} alt="Your Image" width="24px" height="24px" />
                                    <Typography
                                        sx={{
                                            color: "var(--gray-900, #111827)",
                                            fontFamily: "Inter",
                                            fontSize: "18px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "160%",
                                        }}
                                    >Product categorization and filtering.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}

export default OurTeamAccomplishment;
