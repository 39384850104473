import React from "react";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import orangebaclarge from "../Assets/OIP.jpg";
import orangebacsmall from "../Assets/orangebacsmall.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
function ReadyToBuild() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const islargeScreen = useMediaQuery(theme.breakpoints.down("lg"));


    return (
        <Box
            sx={{
                marginLeft: isSmallScreen ? "16px" : (isMediumScreen ? "10px" : "120px"),
                marginRight: isSmallScreen ? "12px" : (isMediumScreen ? "10px" : "120px"),
                padding: isSmallScreen ? "60px 16px" : (isMediumScreen ? "40px 80px" : "40px 160px"), backgroundImage: `url(${isSmallScreen ? orangebacsmall : orangebaclarge})`,
                backgroundSize: "cover",
                borderRadius: "54px",
                display: "flex",
                flexDirection: "column",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                gap: isSmallScreen ? "40px" : "10px",
                minHeight: "348px",
            }}
        >
            <Typography
                sx={{
                    color: "var(--Primary-Dark, #0B132B)",
                    fontFamily: "Inter",
                    fontSize: isSmallScreen ? "32px" : isMediumScreen ? "36px" : "42px",
                    fontWeight: 800,
                    lineHeight: "48px",
                    padding: "20px 10px"
                }}
            >
                Ready to discuss your software requirements? We’re just a click away
            </Typography>

            <Typography
                variant="body1"
                sx={{
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    color: "var(--gray-800, #1F2937)",
                    paddingBottom: "40px"
                }}
            >
                See how we can help you to build your idea into reality
            </Typography>

            <Button
                variant="contained"
                onClick={() => window.open("https://cal.com/naresh-shanmugaraj/discussion", "_blank")}
                sx={{
                    width: "100%",
                    maxWidth: "420px",
                    height: "56px",
                    padding: isSmallScreen ? "8px 24px" : "8px 24px",
                    backgroundColor: "var(--Primary-Dark, #0B132B)",
                    position: "relative",
                    transition: "padding 0.3s ease",
                    "& .MuiButton-endIcon": {
                        opacity: 0,
                        transition: "opacity 0.3s ease-in-out",
                        position: "absolute",
                        top: "50%",
                        right: isSmallScreen ? "0px" : "50px",
                        transform: "translateY(-50%)",
                    },
                    "&:hover": {
                        padding: isSmallScreen ? "8px 42px 8px 2px" : "8px 32px 8px 0px",
                        backgroundColor: "var(--Primary-Dark, #0B132B)",
                        "& .MuiButton-endIcon": {
                            opacity: 1,
                            marginRight: "4px",
                        },
                    },
                }}
                endIcon={<ArrowForwardIcon sx={{ color: "var(--Complementary-Dark, #FF9130)" }} />}
            >
                <Typography
                    sx={{
                        color: "var(--Complementary-Dark, #FF9130)",
                        fontFamily: "Inter",
                        fontSize: isSmallScreen ? "16px" : "20px",
                        fontWeight: 500,
                        lineHeight: "40px",
                        textAlign: "center",
                        paddingLeft: "20px",
                    }}
                >
                    Schedule a meeting now
                </Typography>
            </Button>
        </Box>
    );
}

export default ReadyToBuild;
