import React from "react";
import { AppBar, Container, Toolbar, Box, useMediaQuery } from "@mui/material";
import cogntix from "../Assets/cogntix logo.png";

function TopNavBar() {
    const isSmallScreen = useMediaQuery("(max-width: 600px)");
    const isMediumScreen = useMediaQuery("(max-width: 960px)");

    const logoSize = {
        width: isSmallScreen ? "120px" : (isMediumScreen ? "130px" : "140px"),
        height: isSmallScreen ? "27.78px" : (isMediumScreen ? "30px" : "32.667px")
    };

    const appBarStyle = {
        backgroundColor: "rgba(0, 0, 0, 0)",
        boxShadow: "none",
        padding: isSmallScreen ? "10px 20px" : (isMediumScreen ? "20px 120px" : "40px 104px")
    };

    return (
        <AppBar position="static" sx={appBarStyle}>
            <Container maxWidth={isSmallScreen ? "xs" : (isMediumScreen ? "md" : "xl")} style={{paddingLeft:isMediumScreen?"24px":"10px"}}>
                <Toolbar disableGutters >
                    <Box
                        display="flex"
                        justifyContent={isSmallScreen ? "center" : "center"}
                        width="100%"
                        sx={{justifyContent:isSmallScreen?"center":(isMediumScreen ? "center" : "left")}}
                    >
                        <img
                            src={cogntix}
                            alt="Cogntix Logo"
                            style={logoSize}
                        />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default TopNavBar;
