import React, { useState } from "react";
import { Grid, Typography, Box, useMediaQuery, Snackbar, TextField, Button } from "@mui/material";
import house from "../Assets/house.svg";
import phone from "../Assets/phone.svg";
import envelope from "../Assets/envelope.svg";
import mobilehalf from "../Assets/mobilehalfcircle.png";
import blackdot from "../Assets/black dotted.png";
import orangedot from "../Assets/Dotted orange.png";
import halfblack from "../Assets/halfcircle.png";
import { CircularProgress } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import validator from 'validator'

import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function GetInTouchWithUs() {
    const theme = useTheme();

    const isSmallScreen = useMediaQuery("(max-width: 600px)");
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [formData, setFormData] = useState({
        clientName: '',
        email: '',
        mobileNumber: '',
        notes: ''
    });
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [buttonMessage, setButtonMessage] = useState('Send Message ');
    const patternForMail = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, mobileNumber: value });
        setErrors({ ...errors, mobileNumber: '' });
    };


    const validateForm = () => {
        const errors = {};
        if (!formData.clientName) {
            errors.clientName = 'Client Name is required';
        }

        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!patternForMail.test(formData.email)) {
            errors.email = 'Invalid email format';
        }


        if (!formData.mobileNumber) {
            errors.mobileNumber = 'Mobile Number is required';
        }else {
            let formattedNumber = formData.mobileNumber;

            // Prepend + if not already there
            if (!formattedNumber.startsWith('+')) {
                formattedNumber = '+' + formattedNumber;
            }
    
            const phoneNumber = parsePhoneNumberFromString(formattedNumber);
    
            if (!phoneNumber || !phoneNumber.isValid()) {
                errors.mobileNumber = 'Invalid Mobile Number';
            }
        }
    
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);

        const isValid = validateForm();

        if (isValid) {
            try {
                const formDataString = new URLSearchParams(formData).toString();
                const response = await fetch("https://script.google.com/macros/s/AKfycbynKkfoCykCr4LeScXd8cXZm_060y81AZ5vRynmDBv7NiOoGzEwJ1pjHQLXafsM9wpU/exec", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: formDataString
                });

                if (response.ok) {
                    setFormData({
                        clientName: '',
                        email: '',
                        mobileNumber: '',
                        notes: ''
                    });
                    setSnackbarOpen(true);
                    setButtonMessage('Message Sent');
                    setTimeout(() => {
                        window.location.reload();
                    }, 10000);
                } else {
                    throw new Error('Failed to submit form');
                }
            } catch (error) {
                console.error(error);
            } finally {
                setSubmitting(false);
            }
        } else {
            setSubmitting(false);
        }
    };


    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Grid container spacing={1} sx={{ justifyContent: "center", padding: isSmallScreen ? "10px" : isMediumScreen ? "10px" : "0px 120px 0px 120px", paddingBottom: isSmallScreen ? "40px" : "0px", }}>
            <Grid item xs={12} md={6} lg={6} xl={6} sx={{ padding: "0px", display: "flex", flexDirection: "column", paddingRight: isSmallScreen ? "0px" : "41px" }}>
                <Typography variant="h4" component="h2" sx={{ color: "rgba(255, 145, 48, 1)", fontFamily: "Inter", fontSize: { xs: "16px", sm: "16px", md: "18px", lg: "18px" }, lineHeight: "26px", letterSpacing: "4px", fontWeight: "600", paddingBottom: "8px" }}>
                    CONTACT US
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: "var(--Primary-Dark, #0B132B)", fontFamily: "Inter", fontSize: { xs: "30px", sm: "36px", md: "39px", lg: "42px", xl: "48px" }, fontWeight: "800", lineHeight: "48px" }}>
                    {isSmallScreen ? "Expand your business with Us" : "Get in Touch with us "}
                </Typography>
                <Typography variant="body1" component="p" sx={{ color: 'var(--gray-600, #52525B)', fontFamily: 'Inter', fontSize: { xs: "16px", sm: "16px", md: "16px", lg: "16px" }, fontWeight: '400', lineHeight: '24px', paddingBottom: "50px" }}>
                    Looking for a digital partner to help you with your business? We specialize in product design, SAAS product development, web development and much more. Just fill the form and our team will get in touch with you.
                </Typography>
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "35px", flexDirection: "column", alignSelf: "stretch" }}>
                    <Grid container justifyContent="left" alignItems="center">
                        <Grid item xs={5} lg={3}>
                            <Box sx={{ width: "70px", height: "70px", flexShrink: 0, borderRadius: "5px", background: "rgba(255, 145, 48, 0.05)", borderRadius: "5px", padding: "19px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ height: "52px", width: "52px" }} src={house} alt="House Image" />
                            </Box>
                        </Grid>
                        <Grid item xs={7} sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant="body1" component="p" sx={{ color: 'var(--Dark-Dark, #111928)', paddingBottom: "8px", fontFamily: 'Inter', fontSize: '18px', fontStyle: 'normal', fontWeight: 600, lineHeight: '26px' }}>
                                Our Location
                            </Typography>
                            <Typography variant="body1" component="p" sx={{ color: 'var(--Primary-Text-Color, #637381)', fontFamily: 'Inter', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px' }}>
                                No 218, Stanley road, Jaffna, Sri Lanka
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="left" alignItems="center">
                        <Grid item xs={5} lg={3}>
                            <Box sx={{ width: "70px", height: "70px", flexShrink: 0, borderRadius: "5px", background: "rgba(255, 145, 48, 0.05)", borderRadius: "5px", padding: "19px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ height: "52px", width: "52px" }} src={phone} alt="House Image" />
                            </Box>
                        </Grid>
                        <Grid item xs={7} sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant="body1" component="p" sx={{ color: 'var(--Dark-Dark, #111928)', paddingBottom: "8px", fontFamily: 'Inter', fontSize: '18px', fontStyle: 'normal', fontWeight: 600, lineHeight: '26px' }}>
                                Phone Number
                            </Typography>
                            <Typography variant="body1" component="p" sx={{ color: 'var(--Primary-Text-Color, #637381)', fontFamily: 'Inter', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px' }}>
                                (+1) 205 316 9387<br/>
                                (+94) 77 444 1787
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="left" alignItems="center" paddingBottom={isSmallScreen ? 20 : 0}>
                        <Grid item xs={5} lg={3}>
                            <Box sx={{ width: "70px", height: "70px", flexShrink: 0, borderRadius: "5px", background: "rgba(255, 145, 48, 0.05)", borderRadius: "5px", padding: "19px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ height: "52px", width: "52px" }} src={envelope} alt="House Image" />
                            </Box>
                        </Grid>
                        <Grid item xs={7} sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant="body1" component="p" sx={{ color: 'var(--Dark-Dark, #111928)', paddingBottom: "8px", fontFamily: 'Inter', fontSize: '18px', fontStyle: 'normal', fontWeight: 600, lineHeight: '26px' }}>
                                Email Address
                            </Typography>
                            <Typography variant="body1" component="p" sx={{ color: 'var(--Primary-Text-Color, #637381)', fontFamily: 'Inter', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px' }}>
                                Hai@cogntix.com
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                <Box sx={{
                    borderRadius: '8px',
                    background: 'var(--White-White, #FFF)',
                    gap: "25px",
                    paddingLeft: isSmallScreen ? "0px" : "41px",
                    position: "relative",
                    zIndex: 1
                }}>
                    <img src={isSmallScreen ? blackdot : orangedot} alt="Orangedot" style={{
                        position: "absolute",
                        width: isSmallScreen ? "105px" : "105px",
                        height: isSmallScreen ? "133px" : "133px",
                        right: isSmallScreen ? 30 : -40,
                        top: isSmallScreen ? -28 : 20,
                        zIndex: 2

                    }} />
                    <img src={isSmallScreen ? halfblack : mobilehalf} alt="Ornamenthalfblack" style={{
                        position: "absolute",
                        width: isSmallScreen ? "74px" : "100px",
                        height: isSmallScreen ? "74px" : "100px",
                        right: isSmallScreen ? -20 : -50,
                        top: isSmallScreen ? -60 : -50,
                        zIndex: 1
                    }} />
                    <img src={orangedot} alt="Ornamenthalfblack" style={{
                        position: "absolute",
                        width: isSmallScreen ? "105px" : "105px",
                        height: isSmallScreen ? "133px" : "133px",
                        left: isSmallScreen ? -25 : 15,
                        bottom: isSmallScreen ? -25 : -25,
                        zIndex: 1

                    }} />

                    <Box
                        sx={{
                            borderRadius: '8px',
                            background: 'var(--White-White, #FFF)',
                            boxShadow: '0px 4px 18px 0px rgba(0, 0, 0, 0.07)',
                            padding: isSmallScreen ? "49.95px 38.08px" : "49.94px 50px",
                            gap: "25px",
                            paddingLeft: isSmallScreen ? "0px" : "41px",
                            position: "relative",
                            zIndex: 3
                        }}
                    >

                        <form onSubmit={handleSubmit} style={{}}>
                            <TextField
                                placeholder="Your Name"
                                name="clientName"
                                value={formData.clientName}
                                onChange={handleChange}
                                fullWidth
                                required={false}
                                error={!!errors.clientName}
                                helperText={errors.clientName}
                                sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#FF9130",
                                    },
                                    marginBottom: '25px',
                                    display: "flex",
                                    padding: "12px 16px 12px 20px",
                                    alignItems: "center",
                                    gap: "10px",
                                    flex: "1 0 0",
                                    alignSelf: "stretch"
                                }}
                            />
                            <TextField
                                placeholder="Email"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                fullWidth
                                required
                                error={!!errors.email}
                                helperText={errors.email}
                                sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#FF9130",
                                    },
                                    marginBottom: '25px',
                                    display: "flex",
                                    padding: "12px 16px 12px 20px",
                                    alignItems: "center",
                                    gap: "10px",
                                    flex: "1 0 0",
                                    alignSelf: "stretch"
                                }}
                            />
                            <PhoneInput
                                placeholder="Mobile Number"
                                name="mobileNumber"
                                type="tel"
                                country={'us'}
                                value={formData.mobileNumber}
                                onChange={handlePhoneChange}
                                inputStyle={{ width: '100%' }}
                                containerStyle={{ marginBottom: '25px' }}
                                inputProps={{
                                    name: 'mobileNumber',
                                    required: true,
                                    autoFocus: true,
                                    
                                }}
                                style={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#FF9130",
                                    },
                                    marginBottom: '25px',
                                    display: "flex",
                                    padding: "12px 16px 12px 20px",
                                    alignItems: "center",
                                    gap: "10px",
                                    flex: "1 0 0",
                                    alignSelf: "stretch"
                                }}
                            />
                            {errors.mobileNumber && (
                                <Typography variant="body2" color="error" sx={{display:"flex", justifyContent:"center", marginBottom: '25px' }}>
                                    {errors.mobileNumber}
                                </Typography>
                            )}
                            <TextField
                                placeholder="Additional Information"
                                name="notes"
                                multiline
                                rows={4}
                                value={formData.notes}
                                onChange={handleChange}
                                fullWidth
                                sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#FF9130",
                                    },
                                    marginBottom: '25px',
                                    display: "flex",
                                    padding: "12px 16px 12px 20px",
                                    alignItems: "center",
                                    gap: "10px",
                                    flex: "1 0 0",
                                    alignSelf: "stretch"
                                }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: 'var(--Complementary-Dark, #FF9130)',
                                    width: "100%",
                                    height: "50px",
                                    marginLeft: "20px",
                                    padding: submitting ? '8px 24px' : '8px 32px',
                                    gap: '12px',
                                    fontWeight: "600",
                                    color: 'black',
                                    position: "relative",
                                    '&:hover': {
                                        backgroundColor: 'var(--Complementary-Dark, #FF9130)',
                                    },
                                }}
                            >
                                {submitting ? (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: 'black',
                                            position: "absolute",
                                            top: "35%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                        }}
                                    />
                                ) : (
                                    <React.Fragment>
                                        {buttonMessage}
                                        {buttonMessage === 'Message Sent' && <CelebrationTwoToneIcon />}
                                    </React.Fragment>
                                )}
                            </Button>


                            <Snackbar
                                open={snackbarOpen}
                                autoHideDuration={6000}
                                onClose={handleCloseSnackbar}
                                message="Form submitted successfully!"
                            />
                        </form>

                    </Box>
                </Box>
            </Grid>
        </Grid >
    );
}

export default GetInTouchWithUs;