import React from 'react';
import { Box, Button, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import orangeLogo from "../Assets/Logo_Orange.png";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import styled from 'styled-components';

function Mobilefooter() {
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const StyledInstagramIcon = styled(InstagramIcon)`
    background: #1E293B;
    color: #FFF;
    font-size: 24px;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s ease;

    &:hover {
        background: #FF9130;
        color: #1E293B;
    }
`;
    const StyledfacebookIcon = styled(FacebookOutlinedIcon)`
    background: #1E293B;
    color: #FFF;
    font-size: 24px;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s ease;

    &:hover {
        background: #FF9130;
        color: #1E293B;
    }
`;
    const StyledLinkedinIcon = styled(LinkedInIcon)`
    background: #1E293B;
    color: #FFF;
    font-size: 24px;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s ease;

    &:hover {
        background: #FF9130;
        color: #1E293B;
    }
`;

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Grid
            container
            spacing={0}
            sx={{
                width: "100%",
                height: "535px",
                padding: isSmallScreen ? "60px 16px 40px 16px" : "100px 120px 40px 120px",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(0deg, #0B132B 0%, #0B132B 100%), url(<path-to-image>) lightgray 50% / cover no-repeat",
            }}
        >
            <Grid container display={"flex"} direction={isSmallScreen ? "column" : (isMediumScreen ? "column" : "row")}>
                <Grid item xs={12} lg={12}>
                    <Grid
                        container
                        display={"flex"}
                        direction={isMediumScreen ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            borderBottom: isMediumScreen ? "none" : "1px solid #1E293B",
                            paddingBottom: isMediumScreen ? "0px" : "100px",
                            width: "100%",
                        }}
                    >
                        <Grid item>
                            <Box sx={{ width: isMediumScreen ? "100%" : "144px", height: isMediumScreen ? "auto" : "161px" }}>
                                <img src={orangeLogo} alt="Orange Logo" style={{ width: isSmallScreen ? "144px" : "144px", height: isSmallScreen ? "61px" : "61px", objectFit: "cover" }} />
                            </Box>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                width: "100%",
                                padding: isSmallScreen ? "20px" : "100px 120px 40px 120px",
                                justifyContent: "center",
                                background: "linear-gradient(0deg, #0B132B 0%, #0B132B 100%), url(<path-to-image>) lightgray 50% / cover no-repeat",
                                border: "none",
                                boxShadow: "none"
                            }}
                        >
                            <Grid container display={"flex"} direction={isMediumScreen ? "column" : "row"} alignItems="center">
                                <Grid item sm={6} md={6} sx={{ display: "flex", flexDirection: isMediumScreen ? "row" : "row", gap: "40px" }}>
                                    <Grid item sm={6} md={6}>
                                        <div onClick={() => scrollToSection('aboutUs')}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color: '#F4F4F5',
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    lineHeight: '24px',
                                                    marginBottom: isMediumScreen ? "20px" : 0,
                                                    '&:hover': {
                                                        color: 'orange',
                                                        cursor: 'pointer'
                                                    },
                                                }}
                                            >
                                                About Us
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item sm={6} md={6}>
                                        <div onClick={() => scrollToSection('ourServices')}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color: '#F4F4F5',
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    lineHeight: '24px',
                                                    marginBottom: isMediumScreen ? "20px" : 0,
                                                    marginLeft: isMediumScreen ? 0 : "20px",
                                                    '&:hover': {
                                                        color: 'orange',
                                                        cursor: 'pointer'
                                                    },
                                                }}
                                            >
                                                Services
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item sm={6} md={6}>
                                        <div onClick={() => scrollToSection('OurStory')}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color: '#F4F4F5',
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    lineHeight: '24px',
                                                    marginBottom: isMediumScreen ? "20px" : 0,
                                                    marginLeft: isMediumScreen ? 0 : "20px",
                                                    '&:hover': {
                                                        color: 'orange',
                                                        cursor: 'pointer'
                                                    },
                                                }}
                                            >
                                                Our Story
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12} md={12} sx={{ display: "flex", justifyContent: "center" }}>
                                    <div onClick={() => scrollToSection('getInTouch')}>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: '#F4F4F5',
                                                fontFamily: 'Inter',
                                                fontSize: '16px',
                                                fontWeight: 500,
                                                lineHeight: '24px',
                                                marginBottom: isMediumScreen ? "20px" : 0,
                                                marginLeft: isMediumScreen ? 0 : "20px",
                                                '&:hover': {
                                                    color: 'orange',
                                                    cursor: 'pointer'
                                                },
                                            }}
                                        >
                                            Contact
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ display: "flex", alignContent: "flex-end", gap: "10px" }}>
                            <a href="https://www.linkedin.com/company/cogntix" target="_blank" rel="noopener noreferrer">
                                <  StyledLinkedinIcon />
                            </a>
                            <a href="https://www.facebook.com/WeCogntix/" target="_blank" rel="noopener noreferrer">
                                <StyledfacebookIcon />
                            </a>
                            <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                <StyledInstagramIcon />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
                {isMediumScreen && (
                    <Grid item xs={12} lg={12}>
                        <Divider variant="middle" />
                    </Grid>
                )}


            </Grid>
            <Grid container display={"flex"} direction={isSmallScreen ? "column" : (isMediumScreen ? "column" : "row")}>

                <Grid item xs={12} lg={12} sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between", paddingTop: isMediumScreen ? "0px" : "40px", gap: "40px" }}>
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#CBD5E1',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '22px',
                        }}
                    >
                        32 Main Street, Jaffna, Sri Lanka, 40000
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#CBD5E1',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '22px',
                        }}
                    >
                        © Copyright 2024, All Rights Reserved by Cogntix
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Mobilefooter;
