import React from "react";
import { useMediaQuery } from "@mui/material";
// import hero from "../Assets/Hero image1.png";
import hero from "../Assets/Hero image1.jpg";


function ImageHeroFirstrow() {
    const isMobile = useMediaQuery("(max-width:600px)");
    const isTablet = useMediaQuery("(max-width:960px)");

    const styles = {
        default: {
            width: "100%",
            height: "auto",
        },
        mobile: {
            width: "100%",
            height: "auto",
        },
        tablet: {
            width: "100%",
            height: "auto",
        },
    };

    const imageStyle = isMobile ? styles.mobile : isTablet ? styles.tablet : styles.default;

    return (
        <img
            src={hero}
            alt="Hero Image"
            style={imageStyle}
        />
    );
}

export default ImageHeroFirstrow;
