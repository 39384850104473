import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

function WhoWeAreDetailedMessage() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Box
            sx={{
                paddingLeft: isMediumScreen ? '80px' : '120px',
                paddingTop: isMediumScreen ? '80px' : '100px',
                paddingRight: isMediumScreen ? '20px' : '0px',
                backgroundColor: '#FFF',
                [theme.breakpoints.down('sm')]: {
                    paddingLeft: '20px',
                    paddingTop: '20px',
                },
                [theme.breakpoints.down('md')]: {
                    paddingLeft: '20px',
                    paddingTop: '20px',
                },
            }}
        >
            <Grid container spacing={0}>
                <Grid item xs={12} lg={12}>
                    <Typography
                        variant="h4"
                        component="h2"
                        gutterBottom
                        sx={{
                            color: "rgba(255, 145, 48, 1)",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: { xs: "18px", sm: "18px", md: "16px", lg: "18px" },
                            lineHeight: "26px",
                            letterSpacing: "4px",
                            fontWeight: "600",
                        }}
                    >
                        WHO WE ARE
                    </Typography>

                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        sx={{
                            color: "var(--Primary-Dark, #0B132B)",
                            fontFamily: "Inter",
                            fontSize: { xs: "28px", sm: "32px", md: "24px", lg: "42px", xl: "60px" },
                            fontWeight: "800",
                            lineHeight: "140%",
                        }}
                    >
                        The Team already running a successful
                        SaaS Product

                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            color: 'var(--gray-600, #52525B)',
                            fontFamily: 'Inter',
                            fontSize: { xs: "14px", sm: "18px", md: "14px", lg: "18px" },
                            fontWeight: '400',
                            lineHeight: '160%',
                        }}
                    >
                        Our team has successfully developed and launched a SaaS product tailored for small and micro businesses.
                        FEASTO streamlines the process of establishing an online presence without requiring any technical expertise,
                        enabling businesses to go online in just 3 minutes.

                        <br /><br />
                        Through discussions with numerous business owners, we have recognized a
                        common need for digital solutions to expand reach and enhance operational efficiency.
                        At Team Cogntix, we empathize with the challenges involved in building a business and
                        are committed to supporting you. By handling all technical aspects of your venture,
                        we aim to empower you to focus entirely on operations and growth.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default WhoWeAreDetailedMessage;
